<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>场景+</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/personal_list' }">个人画像</el-breadcrumb-item>
            <el-breadcrumb-item>画像详情</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" type="primary"  @click="goBack">返回</el-button>
      </el-row>
      <el-row class="top">
         <el-col :span="12" class="common-box left-box">
            <el-row class="chart-box">
               <el-row class="title">控制方式占比图</el-row>
               <el-row class="chart-cont">
                  <el-row class="chart" id="like-pie"></el-row>
               </el-row>
            </el-row>
         </el-col>
         <el-col :span="12" class="common-box right-box">
            <el-row class="chart-box">
               <el-row class="title">智能设备使用占比图</el-row>
               <el-row class="chart-cont">
                  <el-row class="chart" id="like-bar"></el-row>
               </el-row>
            </el-row>
         </el-col>
      </el-row>
      <el-row class="center">
         <el-row class="title">购物爱好占比图</el-row>
         <el-row class="chart-cont">
            <el-row class="chart" id="shop-like-bar"></el-row>
         </el-row>
      </el-row>
      <el-row class="bottom">
         <table border="0" width="100%" height="495" border-collapse="collapse">
            <tr style="font-size: 16px">
               <th style="max-width: 200px; height: 60px; background: #f7f7f7">类别</th>
               <th style="background: #f7f7f7">温度</th>
               <th style="background: #f7f7f7">次数</th>
               <th style="background: #f7f7f7" colspan="2">组合</th>
            </tr>
            <tr>
               <th>智能锁</th>
               <td>—</td>
               <td>{{tableData.HOSL.count}}</td>
               <td colspan="2">—</td>
            </tr>
            <tr>
               <th style="background: #f7f7f7">智能窗帘</th>
               <td style="background: #f7f7f7">—</td>
               <td style="background: #f7f7f7">{{tableData.HODR.count}}</td>
               <td style="background: #f7f7f7" colspan="2">—</td>
            </tr>
            <tr>
               <th rowspan="4">场景面板</th>
               <td rowspan="4">—</td>
               <td rowspan="4">—</td>
               <td style="width: 200px">{{tableData.HOSC.combinationList[0].index}}</td>
               <td style="width: 200px;">{{tableData.HOSC.combinationList[0].count}}</td>
            </tr>
            <tr>
               <td>{{tableData.HOSC.combinationList[1].index}}</td>
               <td>{{tableData.HOSC.combinationList[1].count}}</td>
            </tr>
            <tr>
               <td>{{tableData.HOSC.combinationList[2].index}}</td>
               <td>{{tableData.HOSC.combinationList[2].count}}</td>
            </tr>
            <tr>
               <td>{{tableData.HOSC.combinationList[3].index}}</td>
               <td>{{tableData.HOSC.combinationList[3].count}}</td>
            </tr>
            <tr>
               <th style="background: #f7f7f7">温控面板</th>
               <td style="background: #f7f7f7">{{tableData.HOTC.temperature}}</td>
               <td style="background: #f7f7f7">—</td>
               <td style="background: #f7f7f7" colspan="2">—</td>
            </tr>
            <tr>
               <th>电视</th>
               <td>—</td>
               <td>{{tableData['HOIN:8192'].count}}</td>
               <td colspan="2">—</td>
            </tr>
            <tr>
               <th style="background: #f7f7f7">空调</th>
               <td style="background: #f7f7f7">—</td>
               <td style="background: #f7f7f7">{{tableData['HOIN:49152'].count}}</td>
               <td style="background: #f7f7f7" colspan="2">—</td>
            </tr>
            <tr>
               <th>智能开关</th>
               <td>—</td>
               <td>{{tableData.HOSW.count}}</td>
               <td colspan="2">—</td>
            </tr>
         </table>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   export default {
      data() {
         return{
            tableData: {
               HOSL: {
                  count: "106"
               },
               HODR: {
                  count: "0"
               },
               HOSC: {
                  combinationList: [
                     {
                        index: "欢迎模式",
                        count: 12
                     },
                     {
                        index: "明亮模式",
                        count: 1
                     },
                     {
                        index: "阅读模式",
                        count: 0
                     },
                     {
                        index: "睡眠模式",
                        count: 0
                     }
                  ]
               },
               HOTC: {
                  temperature: "21度"
               },
               "HOIN:8192": {
                  count: "0"
               },
               "HOIN:49152": {
                  count: "0"
               },
               HOSW: {
                  count: "359"
               }
            },
            name: '',           // 姓名
            mobile: '',         // 手机号
            age: 0,             // 年龄
            certificateNo: '',  // 身份证号
            index: 1,
            /* 控制方式 */
            pieOptions: {
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               legend: {
                  orient: 'vertical',
                  left: 10,
                  top: 40,
                  data: ['按键控制', '智能语音', '小程序客控']
               },
               color:['#ffa333', '#3edbb7', '#0272ff'],
               series: [
                  {
                     name: '控制方式',
                     type: 'pie',
                     radius: '55%',
                     center: ['50%', '60%'],
                     data: [
                        {value: 35, name: '按键控制'},
                        {value: 210, name: '智能语音'},
                        {value: 85, name: '小程序客控'},
                     ],
                     emphasis: {
                        itemStyle: {
                           shadowBlur: 10,
                           shadowOffsetX: 0,
                           shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                     }
                  }
               ]
            },  // 饼图配置项
            /* 智控设备使用 */
            barOptions: {
               title: {
                  text: '智控设备使用占比图',
                  left: 'center'
               },
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'shadow'
                  }
               },
               color: ['#0272ff'],
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {},
               yAxis: {
                  type: 'category',
                  data: []
               },
               series: [
                  {
                     name: '智控设备',
                     type: 'bar',
                     barWidth: '60%',
                     data: [],
                     itemStyle: {
                        normal: {
                           label: {
                              show: true,		  //开启显示
                              position: 'right',	//在上方显示
                              textStyle: {	    //数值样式
                                 color: 'black',
                                 fontSize: 14
                              }
                           }
                        }
                     }
                  }
               ]
            },
            /* 购物爱好 */
            barOptions1: {
               title: {
                  text: '购物爱好',
                  left: 'center'
               },
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'shadow'
                  }
               },
               color: ['#0272ff'],
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {},
               yAxis: {
                  type: 'category',
                  data: []
               },
               series: [
                  {
                     name: '购物爱好',
                     type: 'bar',
                     barWidth: '60%',
                     data: [],
                     itemStyle: {
                        normal: {
                           label: {
                              show: true,		    //开启显示
                              position: 'right',
                              textStyle: {	    //数值样式
                                 color: 'black',
                                 fontSize: 14
                              }
                           }
                        }
                     }
                  }
               ]
            },
         }
      },
      mounted() {
         this.hotelId = this.$route.query.hotelId
         this.mobile = this.$route.query.mobile
         this.name = this.$route.query.name
         let certificateNo = this.$route.query.certificateNo
         this.computeAge(certificateNo)
         this.getPersonalPortrait()
      },
      methods: {
         // 获取个人画像数据
         getPersonalPortrait(){
            const url = urlObj.personalPortrait + `?mobile=${this.mobile}&hotelId=${this.hotelId}`
            this.$axios.post(url, {}).then(res => {
               if (res.success){
                  if (!res.hasOwnProperty('records')) return;
                  // 设备使用次数表格
                  this.tableData = res.records.twoDimensionsMap
                  // 控制方式占比
                  let controlTypeList = res.records.controlTypeList
                  controlTypeList = controlTypeList.map(item => {
                     let name
                     if (item.index === 'voice') name = '智能语音'
                     if (item.index === 'applets') name = '小程序客控'
                     if (item.index === 'key') name = '按键控制'
                     return {name, value: item.count}
                  })
                  this.pieOptions.series[0].data = controlTypeList
                  this.$echarts.init(document.getElementById('like-pie')).setOption(this.pieOptions)
                  // 智控设备使用占比
                  let deviceUseList = res.records.deviceUseList
                  if (deviceUseList.length === 0) return;
                  this.barOptions.yAxis.data = []
                  this.barOptions.series[0].data = []
                  deviceUseList.forEach(item => {
                     let name
                     if (item.index === 'HOSW') name = '智能开关'
                     if (item.index === 'HODR') name = '智能窗帘'
                     if (item.index === 'HOSL') name = '智能锁'
                     if (item.index === 'HOIN:8192') name = '电视'
                     if (item.index === 'HOIN:49152') name = '空调'
                     this.barOptions.yAxis.data.push(name)
                     this.barOptions.series[0].data.push(item.count)
                  })
                  this.$echarts.init(document.getElementById('like-bar')).setOption(this.barOptions)
                  // 购物爱好
                  let shoppingHobbyList = res.records.shoppingHobbyList
                  if (shoppingHobbyList.length === 0) return
                  this.barOptions1.yAxis.data = []
                  this.barOptions1.series[0].data = []
                  shoppingHobbyList.forEach(item => {
                     this.barOptions1.yAxis.data.push(item.index)
                     this.barOptions1.series[0].data.push(item.count)
                  })
                  this.$echarts.init(document.getElementById('shop-like-bar')).setOption(this.barOptions1)
               }
            })
         },
         // 根据身份证号计算年纪
         computeAge(certificateNo){
            if(certificateNo != null){
               //获取年龄
               let myDate = new Date();
               let month = myDate.getMonth() + 1; // 当前月
               let day = myDate.getDate();        // 当前日
               let age = myDate.getFullYear() - certificateNo.substring(6, 10) - 1;
               if (certificateNo.substring(10, 12) < month || certificateNo.substring(10, 12) == month && certificateNo.substring(12, 14) <= day) {
                  age++;
               }
               this.age = age
            }
         },
         // 返回上页
         goBack(){
            this.$router.go(-1)
         }
      }
   }
</script>

<style lang="scss" scoped>
   .top{
      color: #222222; margin-bottom: 20px;
      .left-box { padding-right: 10px }
      .right-box{ padding-left: 10px }
      .common-box{
         .chart-box{
            background: #ffffff; box-shadow: 0 0 8px #DCE7F9;
            .title{
               line-height: 60px; background: #f7f7f7; font-size: 16px;
               padding-left: 30px;
            }
            .chart-cont{
               padding: 20px;
               .chart{ height: 300px }
            }
         }
      }
   }
   .center{
      background: #ffffff!important; margin-bottom: 20px;
      box-shadow: 0 0 8px #DCE7F9;
      .title{
         line-height: 60px; background: #f7f7f7; font-size: 16px;
         padding-left: 30px;
      }
      .chart-cont{
         .chart{ height: 300px }
      }
   }
   .bottom{
      background: #ffffff!important; box-shadow: 0 0 8px #DCE7F9;
   }
</style>
